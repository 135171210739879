import * as React from "react";
import "../styles/global.css";
import "../styles/earthrise.css";
// import background from "../../../static/images/background.webp";
import { Helmet } from "react-helmet";
import Font1 from "../../static/fonts/made_evolve_sans_medium-webfont.woff2";
import Font2 from "../../static/fonts/made_evolve_sans_regular-webfont.woff2";

// markup
const IndexPage = () => {
	return (
		<>
			<Helmet>
				<title>Earthrise Tech</title>
				<meta name="description" content="Earthrise Tech has built a portfolio of innovative technical products and services in earth sciences and urban development, demonstrating competencies in a wide range of skills and fields." />
				<link rel="preload" as="font" type="font/woff2" crossOrigin="anonymous" href={Font1} />
				<link rel="preload" as="font" type="font/woff2" crossOrigin="anonymous" href={Font2} />
			</Helmet>
			<main className="er-main">
				<div id="er-frame-master">
					<div className="er-frame-item-half er-frame-item-half-vertical">&nbsp;</div>
					<div className="er-frame-item-half">&nbsp;</div>
					<div className="er-frame-item er-title-row" id="title-horizontal">
						<div className="er-title-logo">Earthrise</div>
						<div className="er-title">&nbsp;</div>
					</div>
					<div className="er-frame-item er-info">
						Urban Planning + Natural Hazards
						<br />Data Analysis + GIS + Apps
						<br />&nbsp;
						<br /><a className="er-a" href="/portfolio">Portfolio</a>
						<br /><a className="er-a" href="/services">Urban Planning Services</a>
						<br /><a className="er-a" href="/sunchaser">Sun Chaser App</a>
						<br /><br />Contact: <a className="er-a" href="mailto:info@earthrise.tech?subject=Web%20Enquiry" target="_blank" rel="noopener noreferrer">info@earthrise.tech</a>
					</div>
					<div className="er-frame-item-half">&nbsp;</div>
					<div className="er-frame-item-half er-frame-item-half-vertical">&nbsp;</div>
					<div className="er-frame-item-half er-frame-item-half-vertical">&nbsp;</div>
				</div>
			</main>
		</>
	)
}

export default IndexPage
